import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'themes/components';
import { my, mb, ml, mr, mt, mx, px, pl, pt } from 'styled-components-spacing';
import { map } from 'styled-components-breakpoint';
import { Link } from 'gatsby';

import Brand from './Brand';
import TwitterLogo from './Icons/Twitter';
import LinkedinLogo from './Icons/Linkedin';
import YouTubeLogo from './Icons/Youtube';
import DiscordLogo from './Icons/Discord';
import ExternalIcon from './Icons/External';
import DownloadIcon from './Icons/Download';
import PrivacyPolicyFile from '../pages/terms-of-service/Mudstack_Privacy_Policy.pdf';
import CustomerTermsFile from '../pages/terms-of-service/Mudstack_Customer_Terms_of_Service.pdf';
import UserTermsFile from '../pages/terms-of-service/Mudstack_User_Terms_of_Service.pdf';
import GlowBorder from './GlowBorder';

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 40px auto 0;
  ${px(2)};
  background-color: ${colors.grey90};
  position: relative;
  overflow: hidden;
`;

const FooterTop = styled.div`
  color: ${colors.grey5};
  display: flex;
  ${map(
    {
      mobile: 'column',
      tablet: 'row'
    },
    (direction) => `flex-direction: ${direction};`
  )}
  width: 100%;
  ${pt({ mobile: 3, tablet: 5 })};
  ${mb({ desktop: 1 })};
`;

const LinksContainer = styled.div`
  ${map(
    {
      mobile: '0 0 100%',
      tablet: '0 0 80%'
    },
    (flex) => `flex: ${flex};`
  )}
  display: grid;
  grid-gap: 24px;
  ${mb({ mobile: 2, tablet: 0 })}
  grid-template-columns: repeat(auto-fit, minmax(125px, auto));
`;

const FooterBottom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ${mb(2)}
    & > a {
      color: ${colors.grey30};
      margin-top: 0;
      line-height: 24px;
      &:hover {
        color: ${colors.grey5};
      }
    }
    & > span {
      color: ${colors.grey50};
      margin: 0 4px;
    }
  }
  ${map(
    {
      mobile: '24px auto 32px auto',
      tablet: '24px auto 48px auto'
    },
    (margin) => `margin: ${margin};`
  )}
  align-items: baseline;
`;

const Copyright = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.grey30};
`;

const Links = styled.div`
  ${map(
    {
      mobile: '0 0 50%',
      desktop: 'unset'
    },
    (flex) => `flex: ${flex};`
  )}
`;

const SectionHeader = styled.h4`
  color: ${colors.grey30};
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
`;

const BrandContainer = styled.div`
  ${mb({ mobile: 2, tablet: 0 })}
  ${map(
    {
      tablet: 'auto'
    },
    (ml) => `margin-left: ${ml};`
  )}
`;

const linkStyles = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 8px;
  transition: color 0.3s ease;
  &:hover,
  &:focus {
    color: ${colors.grey20};
  }
`;

const InternalLink = styled(Link)`
  ${linkStyles}
  display: flex;
  align-items: center;
`;

const ExternalLink = styled.a`
  ${linkStyles}
`;

const Badge = styled.span`
  font-size: 12px;
  font-weight: 500;
  /* line-height: 1; */
  /* text-transform: uppercase; */
  letter-spacing: 0.2px;
  color: ${colors.teal40};
  background: ${colors.teal40}20;
  ${ml(0)};
  padding: 0 8px;
  border-radius: 4px;
`;

const Social = styled.div`
  display: flex;
  ${mt(0)};
  & > a {
    margin: 0;
    ${mr(0)};
  }
`;

const Footer = () => (
  <Container>
    <GlowBorder
      borderColor="hsla(171, 69%, 82%, 0.2)"
      glow1="hsla(197, 100%, 75%, 0.38)"
      glow2="hsla(212, 68%, 52%, 0.52)"
      glow3="hsla(24, 95%, 85%, 0.4)"
    />
    <FooterTop>
      <LinksContainer>
        <Links>
          <SectionHeader>Solutions</SectionHeader>
          <InternalLink to="/for-artists">For artists</InternalLink>
          <InternalLink to="/for-art-leads">For art leads</InternalLink>
          <InternalLink to="/for-engineers">For engineers</InternalLink>
          <ExternalLink
            target="blank"
            download
            href="https://mudstack-desktop.s3.amazonaws.com/mudstack_windows_latest.exe"
          >
            Download for Windows
            <DownloadIcon
              fillColor={`${colors.grey40}`}
              style={{ marginLeft: '4px' }}
            />
          </ExternalLink>
          <ExternalLink
            target="blank"
            download
            href="https://mudstack-desktop.s3.amazonaws.com/mudstack_mac_latest.dmg"
          >
            Download for Mac
            <DownloadIcon
              fillColor={`${colors.grey40}`}
              style={{ marginLeft: '4px' }}
            />
          </ExternalLink>
        </Links>
        <Links>
          <SectionHeader>Features</SectionHeader>
          <InternalLink to="/features#cloud">Cloud storage</InternalLink>
          <InternalLink to="/features#review">Review & feedback</InternalLink>
          <InternalLink to="/features#versions">Version control</InternalLink>
          <ExternalLink href="https://docs.mudstack.com/security">
            Security
          </ExternalLink>
        </Links>
        <Links>
          <SectionHeader>Product</SectionHeader>
          <InternalLink to="/pricing">Pricing</InternalLink>
          <InternalLink to="/desktop">Desktop App</InternalLink>
          <ExternalLink href="https://docs.mudstack.com">
            Documentation
          </ExternalLink>
          <ExternalLink href="https://mudstack.canny.io/" target="blank">
            Roadmap
            <ExternalIcon
              fillColor={`${colors.grey40}`}
              style={{ marginLeft: '4px' }}
            />
          </ExternalLink>
          <ExternalLink
            href="https://mudstack.canny.io/changelog"
            target="blank"
          >
            Changelog
            <ExternalIcon
              fillColor={`${colors.grey40}`}
              style={{ marginLeft: '4px' }}
            />
          </ExternalLink>
        </Links>
        <Links>
          <SectionHeader>Company</SectionHeader>
          <InternalLink to="/about">About</InternalLink>
          <InternalLink to="/blog">Blog</InternalLink>
          <InternalLink to="/careers">
            Careers <Badge>We're Hiring!</Badge>
          </InternalLink>
          <InternalLink to="/press">Press</InternalLink>
        </Links>
        <Links>
          <SectionHeader>Compare</SectionHeader>
          <InternalLink to="/vs-google-drive">vs Google Drive</InternalLink>
          <InternalLink to="/vs-dropbox">vs Dropbox</InternalLink>
          {/* <InternalLink to="/vs-syncsketch">vs SyncSketch</InternalLink>
          <InternalLink to="/vs-perforce">vs Perforce</InternalLink> */}
        </Links>
      </LinksContainer>
      <BrandContainer>
        <Brand />
        <Social>
          <ExternalLink href="https://discord.gg/eXbMXKb5fY" target="_blank">
            <DiscordLogo hoverFill={colors.discord} />
          </ExternalLink>
          <ExternalLink href="https://twitter.com/mudstack" target="_blank">
            <TwitterLogo hoverFill={colors.twitter} />
          </ExternalLink>
          <ExternalLink
            href="https://linkedin.com/company/mudstack"
            target="_blank"
          >
            <LinkedinLogo hoverFill={`${colors.linkedin}`} />
          </ExternalLink>
          <ExternalLink
            href="https://www.youtube.com/channel/UCzEYEPcsnwYkjLxqiINjBHQ"
            target="blank"
          >
            <YouTubeLogo hoverFill={`${colors.youtube}`} />
          </ExternalLink>
        </Social>
      </BrandContainer>
    </FooterTop>
    <FooterBottom>
      <div>
        <InternalLink to="/privacy-policy">Privacy Policy</InternalLink>
        <span>•</span>
        <InternalLink to="/customer-terms">
          Customer Terms of Service
        </InternalLink>
        <span>•</span>
        <InternalLink to="/user-terms">User Terms of Service</InternalLink>
        {/* <ExternalLink href={PrivacyPolicyFile} download>
          Privacy Policy
        </ExternalLink>
        <span>•</span>
        <ExternalLink href={CustomerTermsFile} download>
          Customer Terms of Service
        </ExternalLink>
        <span>•</span>
        <ExternalLink href={UserTermsFile} download>
          User Terms of Service
        </ExternalLink> */}
      </div>
      <Copyright>&copy; {new Date().getFullYear()} Mudstack, Inc.</Copyright>
    </FooterBottom>
  </Container>
);

export default Footer;
