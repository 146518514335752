import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'themes/components';
import { Link } from 'gatsby';
import { map } from 'styled-components-breakpoint';
import { p, pb } from 'styled-components-spacing';
import useOnClickOutside from 'utils/useOnClickOutside';

import Brand from '../Brand';
import TwitterLogo from '../Icons/Twitter';
import DiscordLogo from '../Icons/Discord';
import Solutions from './Solutions';

const Container = styled.div`
  ${map(
    { mobile: 'flex', tablet: 'none', desktop: 'none' },
    (display) => `display: ${display};`
  )}
  align-items: center;
  justify-content: space-between;
  ${p(2)};
  width: 100%;
  z-index: 200;
  background-color: ${colors.grey90};
  position: relative;
  &:after {
    content: '';
    height: 100%;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    height: 1px;
    background: linear-gradient(to right, #121216, #32343e, #121216);
  }
`;

const Burger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 200;
  &:focus {
    outline: none;
  }

  span {
    width: 24px;
    height: 3px;
    background: ${colors.grey20};
    border-radius: 10px;
    transition: opacity 0.15s ease, transform 0.15s ease;
    position: relative;
    transform-origin: 0.5px;

    :first-child {
      transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${(props) => (props.open ? '0' : '1')};
      transform: ${(props) =>
        props.open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const Menu = styled.nav`
  background: ${colors.grey90};
  height: 100%;
  width: 100%;
  color: ${colors.grey30};
  padding: 24px;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
`;

const linkStyles = css`
  font-size: 18px;
  ${pb(1)};
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s linear;
  color: ${colors.grey20};
  display: block;
  &.active,
  &:hover {
    color: ${colors.grey5};
  }
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const ExternalLink = styled.a`
  ${linkStyles}
`;

const MenuItem = styled.a`
  ${linkStyles}
  margin-top: ${(props) => (props.hasBanner ? '98px' : '48px')};
`;

const SolutionsWrapper = styled.div`
  margin-top: 8px;
  margin-left: -12px;
`;

const Mobile = ({ hasBanner }) => {
  const [open, setOpen] = useState(false);
  const menu = useRef();
  useOnClickOutside(menu, () => setOpen(false));
  return (
    <Container ref={menu}>
      <Brand />
      <Burger open={open} onClick={() => setOpen(!open)}>
        <span />
        <span />
        <span />
      </Burger>
      <Menu open={open}>
        <MenuItem activeClassName="active" {...{ hasBanner }}>
          Solutions
          <SolutionsWrapper>
            <Solutions />
          </SolutionsWrapper>
        </MenuItem>
        <StyledLink to="/features" activeClassName="active">
          Features &rarr;
        </StyledLink>
        <StyledLink to="/pricing" activeClassName="active">
          Pricing &rarr;
        </StyledLink>
        <StyledLink to="/about" activeClassName="active">
          About &rarr;
        </StyledLink>
        <StyledLink to="/blog" activeClassName="active">
          Blog &rarr;
        </StyledLink>
        <StyledLink to="/get-a-demo">Get a demo &rarr;</StyledLink>

        <a href="https://discord.gg/eXbMXKb5fY" target="_blank">
          <DiscordLogo width={32} height={32} hoverFill={colors.discord} />
        </a>
        <a
          href="https://twitter.com/mudstack"
          target="_blank"
          style={{ marginLeft: '8px' }}
        >
          <TwitterLogo width={32} height={32} hoverFill={colors.twitter} />
        </a>
      </Menu>
    </Container>
  );
};

export default Mobile;
